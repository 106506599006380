.career_mid{
    width: 80%;
    margin: auto;
}
.forup{
    width: Fixed (1,192px)px;
height: Hug (104px)px;
padding: 24px 0px 0px 0px;
gap: 20px;
border-radius: 5px 0px 0px 0px;

padding: 30px;
background: rgba(255, 255, 255, 1);
box-shadow: 0px 2.71px 4.4px 0px rgba(192, 192, 192, 0.03);

box-shadow: 0px 6.86px 11.12px 0px rgba(192, 192, 192, 0.04);

box-shadow: 0px 14px 22.68px 0px rgba(192, 192, 192, 0.05);

box-shadow: 0px 28.84px 46.72px 0px rgba(192, 192, 192, 0.06);

box-shadow: 0px 79px 128px 0px rgba(192, 192, 192, 0.09);

}
#sein{
    width: Fill (485.5px)px;
height: Fill (56px)px;
padding: 0px 16px 0px 16px;
gap: 16px;

width: 40%;

}
#serch{
    width: 24px;
height: 24px;
padding: 2.78px 2.46px 2px 2.78px;
gap: 0px;
color: rgba(0, 51, 101, 1);
}
#btt{
    width: Fixed (113px)px;
height: Hug (50px)px;
padding: 12px 24px 12px 24px;
gap: 10px;
border-radius: 3px 0px 0px 0px;

background: rgba(0, 51, 101, 1);
font-family: Manrope;
font-size: 16px;
font-weight: 700;
line-height: 25.6px;
text-align: center;
color: #ffff;
}
.car_row{
    display: flex;
    justify-content: space-between;
}
.car_col1{
    flex-basis: 0%;
    text-align: left;
}
.car_col2{
    flex-basis: 100%;
}



.menu ul,
.menu input,
.menu .closer,
.menu input:checked~.opener {
  display: none;
}

.menu input:checked~ul,
.menu input:checked~.closer {
  display: block;
} 

.caree_card{
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(214, 221, 235, 1);
    width: Fixed (919px)px;
height: Hug (149px)px;
gap: 0px;
border: 1px 0px 0px 0px;
justify: space-between;


  }
  .card_row{
    display: flex;
    justify-content: space-between;
padding: 20px;
  }
  .card_col1{
flex-basis: 10%;
  }
  .car_col1 img{
    width: 100%;
  }
  .card_col2{
    flex-basis: 59%;
  }
  .card_col2 h2{
font-family: Epilogue;
font-size: 20px;
font-weight: 600;
line-height: 24px;
text-align: left;
color: rgba(37, 50, 75, 1);
  }
  .card_col2 p{
font-family: Epilogue;
font-size: 16px;
font-weight: 400;
line-height: 25.6px;
text-align: left;
color: rgba(124, 132, 147, 1);

  }
  .card_col3{
    flex-basis: 29%;
  }
.card_col3 button{
    width: 200px;
height: Hug (50px)px;
padding: 12px 24px 12px 24px;
gap: 10px;
border-radius: 3px 0px 0px 0px;

font-family: Epilogue;
font-size: 16px;
font-weight: 700;
line-height: 25.6px;
text-align: center;
color: #fff;
background: rgba(0, 51, 101, 1);

}
.rooww{
  display: flex;
  align-items: center;

}
.col11a{
  flex-basis: 15%;
}
.col11a img{
  width: 100%;
}
.col2aa{
  flex-basis: 85%;
}
.col2aa h2{
  font-family: Manrope;
  font-size: 36px;
  font-weight: 600;
  line-height: 57.6px;
  text-align: center;
color: black;  
}
.col2aa p{
  font-family: Manrope;
font-size: 20px;
font-weight: 400;
line-height: 32px;
text-align: center;
color: black;
}
hr{
height: 5px;
top: 1210px;
left: 248px;
gap: 0px;
border-width: 5px;

color:rgb(5, 22, 40);

}

@media (max-width:800px) {
    .car_row,.card_row,.rooww{
    flex-direction: column;
    }
    .car_col1{
        width: 100%;

    }
    .car_col2{
        margin-top: 5%;
    }
    .card_col3 button{
        width: 100%;
    }
    #sein{
     width: 80%;
     margin-bottom: 4%;
    
    }
    #btt{
        width: 90%;
    }
    
}