
 .sec__last_1{
    background: #003365;
    width: 100%;
    height: 250px;
    top: 1948px;
    left: 83px;
    gap: 0px;
text-align: center;
 }
 .sec__last1m{
    width: 80%;
    margin: auto;
    text-align: center;
 }
 .sec__last1m h2{
    padding-top: 40px;
    font-family: Manrope;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #FFFFFF;
 }
 .sec__last1m p{
    font-family: Manrope;
 font-size: 26px;
 font-weight: 400;
 
 text-align: center;
 color: #FFFFFF;
 margin-top: 10px;
 }
 .sec__last1m button{
   width: Hug (218px)px;
   height: Hug (46px)px;
   top: 2111px;
   left: 615px;
   padding: 14px 63px 14px 63px;
   gap: 10px;
   border-radius: 5px ;
   font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 18.24px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #003365;

 margin-top: 10px;
 }
 
 @media (max-width:800px) {
    .sec__last1m button{

     margin-left: 30.5%;

     }
    
 }

 @media (max-width:500px) {
    .sec__last1m button{

     margin-left: 20.5%;

     }
    
 }