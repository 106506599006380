@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap');
body{
   background: #F8F8FD;

}
body{
    
   overflow-X: hidden;
}
html {
   overflow-x: hidden;
 }
 
 body {
   position: relative;
   overflow-x: hidden;

 }

.headertp{

    width: 80%;
    margin: auto;
    height: 86vh;
   }
   .headertp2{

      width: 80%;
      margin: auto;
     }
.h_row{
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.rowwww{
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.h_col{
   flex-basis: 50%;
}

.h_col h1{
   font-family: Manrope;
font-size: 72px;
font-weight: 544;
line-height: 82.08px;
text-align: left;

}
.h_col h2{
   font-family: Epilogue;
font-size: 16px;
font-weight: 400;
line-height: 25.6px;
text-align: left;

}
.h_col button{
  
padding: 14px 63px 14px 63px;
gap: 10px;
border-radius: 5px 0px 0px 0px;

font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 18.24px;
text-align: left;
background: #003365;
color: #FFFFFF;

}
.h_col2a img{
   width: 100%;
}
.sec_2{
   width: 80%;
   margin: auto;
}
.sec_2 h2{
   font-family: Manrope;
font-size: 60px;
font-weight: 600;
line-height: 81.96px;
text-align: left;
color: #003365;
}
.h_col2{
   flex-basis: 24%;
   width: Hug (274px)px;
height: Hug (214px)px;
padding: 32px 0px 0px 0px;
gap: 32px;
border: 1px 0px 0px 0px;

border: 1px solid #D6DDEB;

background: #FFFFFF;

padding: 20px;
}

.h_col2 img{
   width: 15%;
}


.h_col2 p{
   font-family: Epilogue;
   font-size: 18px;
   font-weight: 400;
   line-height: 28.8px;
   text-align: left;
   color: #7C8493;
  margin-top: 2px;
}


a:hover{
text-decoration: none;

}

.home__div{
   width: 80%;
   margin: auto;
}
.home__div h2{
   font-family: Manrope;
font-size: 60px;
font-weight: 600;
line-height: 81.96px;
text-align: left;
color: #003365;
}
.home__row{
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.home__col{
   flex-basis: 24%;
   border: 1px solid rgba(214, 221, 235, 1);
   background: rgba(255, 255, 255, 1);
   padding: 20px;
width: 100%;
height:30vh;
}
.home__col h2{
   font-family: Manrope;
font-size: 24px;
font-weight: 600;
line-height: 28.8px;
text-align: left;
color: rgba(37, 50, 75, 1);
;
margin-top: 5%;
}
.home__col img{
   width: 15%;
}
.home__col p{
   margin-top: 2%;
font-family: Epilogue;
font-size: 18px;
font-weight: 400;
line-height: 28.8px;
text-align: left;
color: rgba(124, 132, 147, 1);

}

.h_section3{
   width: 80%;
   margin: auto;
   display: none;
}
.h_section4{
   width: 80%;
   margin: auto;
}
.h_section3 h2,.h_section4 h2{
   font-family: Manrope;
font-size: 60px;
font-weight: 600;
line-height: 81.96px;
text-align: left;
color: #003365
;
}
.bl1{

padding: 12px 124px 12px 16px;
gap: 8px;
border-radius: 8px 8px 0px 0px;

background: #003365;

}
.bl1 h3,.bl2 h3{
   font-family: Inter;
font-size: 14px;
font-weight: 600;
line-height: 16.94px;
text-align: left;
color:#FFFFFF;
}
.bl2{
padding: 12px 124px 12px 16px;
gap: 8px;

background: #007C80;
margin-top: 6px;
}
.lst_sec{
   width: 80%;
   margin: auto;
}
.lst_sec h2{
   font-family: Manrope;
   font-size: 60px;
   font-weight: 600;
   line-height: 81.96px;
   text-align: left;
   color: #003365
   ;
}
.mh_row{
display: flex;
}
.mh_col{

height: Hug (21px)px;
padding: 12px 124px 12px 16px;

border-radius: 8px 8px 0px 0px;
background: rgba(0, 51, 101, 1);
flex-basis: 27%;
font-family: Inter;
font-size: 14px;
font-weight: 600;
padding-top: 22px;
text-align: left;
color: #FFFFFF;
}

.mh_col2{
 
flex-basis: 22%;
padding: 8px;
margin-left: 28px;

}
.mh_col3{

   height: Hug (41px)px;
   padding: 12px 124px 12px 16px;
   gap: 8px;
   background: rgba(0, 124, 128, 1);
   flex-basis: 50%;
   font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16.94px;
text-align: left;
color: #FFFFFF;
}



@media (max-width:800px) {

    .h_row,.home__row{
      flex-direction: column;
    }
    .rowwww{
      flex-direction: column;
    }
    .h_col h1{
      font-family: Manrope;
   font-size: 42px;
   font-weight: 544;
   line-height: 82.08px;
   text-align: left;
   
   }
   .h_col img{
      width: 80%;
   }
    .h_col2a{
      display: none;
    }
    .h_col2a img{
     visibility: hidden;
    }
    .headertp{

      width: 80%;
      margin: auto;
      height: 80vh;
     }
     .h_col2{
      width: 100%;
      margin-bottom: 5%;
     }
     .h_col h1{
      font-family: Manrope;
   font-size: 42px;
   font-weight: 544;
   line-height: 82.08px;
   text-align: left;
   }
   .h_col h2{
      font-family: Epilogue;
   font-size: 16px;
   font-weight: 400;
   line-height: 25.6px;
   text-align: left;
   
   }
   .h_col button{
     
   padding: 14px 63px 14px 63px;
   gap: 10px;
   border-radius: 5px 0px 0px 0px;
   
   font-family: Poppins;
   font-size: 16px;
   font-weight: 500;
   line-height: 18.24px;
   text-align: left;
   background: #003365;
   color: #FFFFFF;
   
   }

   .headertp2{

      width: 80%;
      margin: auto;
      height: 60vh;
     }
     .h_section4{
      width: 80%;
      margin: auto;
      display: none;
   }
}