.headertp1{

    width: 80%;
    margin: auto;
    height: 90vh;
   }
.about__us_mid{
    height: 60vh;
    background:linear-gradient(rgba(0, 51, 101, 0.8),rgba(0, 51, 101, 0.6)), url(./abbg.png);
    background-position:center ;
    background-size: cover;

}
.ab h2{
    font-family: Manrope;
font-size: 60px;
font-weight: 544;
line-height: 68.4px;
text-align: center;
color: rgba(255, 255, 255, 1)
;
padding-top: 100px;
}
.ab p{
    font-family: Epilogue;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    text-align: center;
color: rgba(255, 255, 255, 1)
;
}
.ab__mid{
    width: 80%;
    margin: auto;
}
.ab__mid h2{
    font-family: Manrope;
font-size: 60px;
font-weight: 544;
line-height: 68.4px;
color: rgba(0, 51, 101, 1);
text-align: center;
}
.ab__mid p{
    font-family: Epilogue;
font-size: 16px;
font-weight: 400;
line-height: 25.6px;
text-align: center;
color: rgba(0, 51, 101, 1);

}
.ab__row{
    display: flex;
    justify-content: space-between;

}
.ab__col{
    flex-basis: 49%;
    background: rgba(0, 51, 101, 1);
  padding: 40px;
  width: Fixed (1,281px)px;
  height: Hug (41px)px;
 
}
.ab__col h2{
    font-family: Manrope;
font-size: 24px;
font-weight: 544;
line-height: 27.36px;
text-align: left;
color: rgba(255, 255, 255, 1);
}
.ab__col p{
    font-family: Epilogue;
font-size: 16px;
font-weight: 400;
line-height: 25.6px;
text-align: left;
color: rgba(255, 255, 255, 1);
margin-top: 3px;
}
.ab__colh{
    flex-basis: 24%;
    width: Hug (274px)px;
 height: Hug (214px)px;
 padding: 32px 0px 0px 0px;
 gap: 32px;
 border: 1px 0px 0px 0px;

 border: 1px solid #D6DDEB;
 
 background: #FFFFFF;
 
 padding: 20px;
 }
 .lst_row{
    display: flex;
    justify-content: space-between;
 }
 
 .lst_col{
    flex-basis: 33%;
    width: Fixed (419px)px;
 height: Fixed (204px)px;
 top: 2413px;
 left: 83px;
 padding: 24px 0px 0px 0px;
 gap: 0px;
  box-shadow: 0px 0px 10px 0px #00000040;
 background: #FFFFFF;
 padding: 13px;
 }
 .lst_col p{
    font-family: Manrope;
 font-size: 15px;
 font-weight: 400;
 line-height: 21px;
 text-align: left;
 color: #000000;
 }
 .ro_m{
    display: flex;
    justify-content: space-between;
    align-items: center;
 }
 .ro_col1{
    flex-basis: 30%;
 }
 .ro_col1 img{
    width: 100%;
 }
 .ro_col2{
    flex-basis: 70%;
 }
 .ro_col2 h3{
 font-family: Inter;
 font-size: 16px;
 font-weight: 400;
 line-height: 22.4px;
 text-align: left;
 color: #3C3C43D9;
 
 }
 .ro_col2 p{
    font-family: Manrope;
 font-size: 15px;
 font-weight: 400;
 line-height: 21px;
 text-align: left;
 color: #000000
 ;
 }
@media (max-width:800px) {
    .headertp1{
        height: 40vh;
    }
.ab__row{
    flex-direction: column;
}
.ab__col{
    margin-bottom: 3%;
}
.lst_row{
    flex-direction: column;

   }
   .about__us_mid{
    margin-top: 50px;
   }
}